import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About me - Senthil Ganesh" />
    <h1>About</h1>

    <Link to="/">Go back to the homepage</Link>
    <p>Learn, Relearn & Unlearn</p>
    {/* <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-8676170233811690"
      data-ad-slot="4567248565"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins> */}
  </Layout>
)

export default About
